<template>
  <div>
    <v-container>
      <h1 class="pb-6">Register New Livestock</h1>

      <v-card rounded="lg" class="pa-6">
        <v-container>
          <v-row>
            <v-col cols="12">
              <h4>Enter Unique Tag #</h4>
              <v-text-field outlined></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <h4>Livestock Type</h4>
              <v-select outlined></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <h4>Breed</h4>
              <v-select outlined></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <h4>Color</h4>
              <v-select outlined></v-select>
            </v-col>
            <v-col cols="12">
              <h4>
                Gender
              </h4>
              <v-radio-group row>
                <v-radio value="Google">
                  <template v-slot:label>
                    <div>
                      Male
                    </div>
                  </template>
                </v-radio>
                <v-radio value="Duckduckgo">
                  <template v-slot:label>
                    <div>
                      Female
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6">
              <h4>Sire #</h4>
              <v-text-field outlined></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <h4>Dam #</h4>
              <v-text-field outlined></v-text-field>
            </v-col>
            <v-col cols="12">
              <h4>Date of Birth</h4>
              <v-text-field outlined></v-text-field>
            </v-col>
            <v-col cols="12">
              <h4>Date of Sale/Auction Sale</h4>
              <v-text-field outlined></v-text-field>
            </v-col>
            <v-col cols="12">
              <h4>Auction Record of Deathlist</h4>
              <v-text-field outlined></v-text-field>
            </v-col>
            <v-col cols="12">
              <h4>Distinguishing Features</h4>
              <v-textarea outlined></v-textarea>
            </v-col>

            <v-col class="d-flex justify-center" cols="12">
              <v-btn depressed color="primary">Submit</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
